@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('./styles/swiper.css');
@import url('./styles/contact.css');
@import url('./styles/projects.css');
@import url('./styles/about.css');
@import url('./styles/nav.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,600&display=swap');

:root {
  --main-color: #181818;
  --darker-main: #ab20fd;
  --title-font: 'Open Sans', sans-serif;
  --text-font: 'Poppins', sans-serif;
}

* {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  background-color: #282828;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
}

body {
  background: var(--main-color);
}
button {
  padding: 5px 0;
  background-color: var(--main-color);
  border-radius: 1px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 1.1em;
  transition: 0.3s;
  border: none;
  font-family: var(--title-font);
}

button:hover {
  background-color: var(--darker-main);
}

.home {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 45px;
  font-family: var(--title-font);
  font-weight: 700;
  background-color: var(--main-color);
  height: 80vh;
  opacity: 95%;
  z-index: 2;
}

.section__padding {
  padding: 0 25rem;
}

.header__button {
  display: flex;
  gap: 10px;
}

.home-subtitle {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--darker-main);
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  opacity: 20%;
}

.tech_home {
  transition: 0.3s ease-out;
  color: white;
  height: 50px;
}

.tech_home:hover {
  color: #ab20fd;
  transform: scale(1.1);

  cursor: pointer;
}

.home-btn {
  font-size: 26px;
  border: none;
  border: 1px solid var(--darker-main);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  background: linear-gradient(
    to right,
    var(--darker-main) 0%,
    var(--darker-main) 49%,
    var(--main-color) 50%,
    var(--main-color) 100%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.5s;
  font-family: Helvetica, sans-serif;
}

.home-btn:hover {
  background-position: 0 0;
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 2s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s
    both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
}

.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    1.5s both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@media screen and (max-width: 1850px) {
  .section__padding {
    padding: 0 22rem;
  }
}

@media screen and (max-width: 1700px) {
  .section__padding {
    padding: 0 10rem;
  }
}
@media screen and (max-width: 1428px) {
  .section__padding {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 500px) {
  .home-name {
    font-size: 35px;
  }
  .home-subtitle {
    font-size: 30px;
  }
  .home-btn {
    font-size: 20px;
    margin-top: 1rem;
    padding: 0.5rem 0.1rem;
  }
  .header__button {
    margin-top: 0;
  }

  .section__padding {
    padding: 0.5rem;
  }
}
