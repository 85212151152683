.contact {
  display: flex;
  padding-top: 2rem;

  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contact h2 {
  font-family: var(--title-font);
  color: white;
  font-size: 40px;
  text-align: start;
  padding-top: 4rem;
  border-bottom: 1px solid var(--darker-main);
  width: 100%;
  align-self: flex-start;
  margin-bottom: 4rem;
}

.form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-container h3 {
  color: white;
  font-family: var(--text-font);
  font-size: 28px;
}

.contact form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 650px;
  color: #fff;
}
.contact form input,
.contact form textarea {
  width: 100%;
  font-size: 16px;
  font-family: var(--text-font);
  outline: none;
  border: 1px solid var(--darker-main);
  background: #8080800c;
  color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 2px;
}

.contact form label {
  align-self: start;

  font-size: 20px;
  font-family: var(--text-font);
}
.contact form input {
  height: 28px;
}
.contact form button {
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 12px;
  background-color: var(--darker-main);
  width: 100%;
  color: #fff;
  height: 40px;
  font-family: var(--text-font);
}
.contact form button:hover {
  color: #fff;
  transform: scale(1.02);
}

.message {
  background: var(--darker-main);
  color: #fff;
  font-family: var(--text-font);
  padding: 0.5rem 1rem;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px 0;
}

.social__media {
  height: 100px;
  display: flex;
  justify-content: start;
  width: 100%;
  gap: 10px;
  margin-top: 2rem;
}

.social__media-img {
  height: 60%;
  padding: 0.5rem;
  border-radius: 2px;
  background: var(--darker-main);
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.3s ease;
  border: 5px solid var(--darker-main);
}

.social__media-img:hover {
  background: var(--darker-main);
  cursor: pointer;
  transform: scale(1.1);
  border: 5px solid var(--darker-main);
}

.social__media-img img {
  height: 100%;
}

.social__media-img:hover img {
  filter: invert(100%);
}

.upwork_fill:hover path {
  fill: white;
}

.error {
  font-family: var(--text-font);
  color: #fff;
}

.rotate-center {
  -webkit-animation: rotate-center 0.6s ease-in-out infinite both;
  animation: rotate-center 0.6s ease-in-out infinite both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1080px) {
  .form-container {
    flex-direction: column;
    gap: 10px;
  }
}
