/*NAV*/

.nav,
.nav_hide {
  position: sticky;
  top: 0;
  color: #eeeeee;
  z-index: 6;
  transition: 0.5s;
  border-bottom: 0px solid var(--main-color);
}

.nav {
  background-color: var(--main-color);
  border-bottom: 1px solid var(--main-color);
}

.nav__padding {
  padding: 25px 25rem;
}

.dropdown {
  display: none;
}

ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 10px;
  margin: 0 10px;
  font-weight: 500;
  font-size: 1.1em;
}

li {
  transition: all 0.2s;
}

a {
  text-decoration: none;
}

.active-link {
  color: var(--darker-main);
}

ul a {
  color: #eeeeee;
  transition: all 0.2s;
  font-family: var(--text-font);
}

ul a:hover {
  cursor: pointer;
  color: var(--darker-main);
}

@media screen and (max-width: 500px) {
  .dropdown {
    display: flex;
    justify-content: flex-end;
    height: 35px;
  }

  .dropdown a > img {
    height: 100%;
    width: 25px;
    height: 25px;
  }

  .dropdown a > img:hover {
    /* transform: scale(1.1); */
    cursor: pointer;
  }

  .dropdown a:active > img {
    transform: scale(0.9);
  }

  .nav ul,
  .nav_hide ul {
    display: none;
  }

  .dropdown__menu {
    background-color: var(--main-color);
    position: absolute;
    top: 80px;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    right: 0;
    width: 100%;
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .dropdown__menu a {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: var(--text-font);
    font-size: 18px;
  }
  .dropdown__menu a:hover {
    color: var(--darker-main);
    background-color: var(--main-color);

    padding: 1rem 3rem;
  }
}

.tilt-in-top-2 {
  -webkit-animation: tilt-in-top-2 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-top-2 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes tilt-in-top-2 {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-top-2 {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@media screen and (max-width: 1850px) {
  .nav__padding {
    padding: 25px 22rem;
  }
}

@media screen and (max-width: 1700px) {
  .nav__padding {
    padding: 25px 10rem;
  }
}
@media screen and (max-width: 1428px) {
  .nav__padding {
    padding: 25px 4rem;
  }
}

@media screen and (max-width: 500px) {
  .nav__padding {
    padding: 25px.5rem;
  }
}

.grow-from-top {
  animation: grow-from-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  overflow: hidden; /* Ensures content is hidden during scaling */
}

@keyframes grow-from-top {
  0% {
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
    opacity: 1;
  }
}
