/*About*/

#about {
  color: #fff;

  margin: 6rem 0 8rem;
  scroll-margin-top: 100px;
}

.profile-img {
  width: 250px;
  align-self: center;

  border-radius: 50%;
}

.about {
  line-height: 30px;
}

.about-title {
  font-family: var(--title-font);
  padding-bottom: 0.5rem;
  color: white;
  font-size: 40px;
  text-align: start;
  padding-top: 4rem;
  border-bottom: 1px solid var(--darker-main);
}

.about-content {
  width: 100%;
  height: 50px;
  font-size: 28px;
}

p {
  margin: 0;
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.about-info {
  font-family: var(--text-font);
  min-width: 120px;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #fff;
  gap: 20px;
  margin: 2rem 0;
  text-align: start;
}

.svg-img {
  width: 50px;
}

.about-tech {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  background: var(--main-color);
  color: black;
  font-family: var(--text-font);
  padding: 0.5rem;
  border-radius: 3.5px;
}

.about-tech div {
  color: white;
  padding: 0.4rem;
  width: 5rem;
  border-radius: 5px;
  transition: 0.3s ease;
}

.about-tech div:hover {
  scale: 1.1;
}

.tech__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  font-family: var(--title-font);
}
.tech__section h2 {
  padding: 0.5rem;
  text-align: center;
}

.about-tech > div {
  text-align: center;
}

.tech_icon {
  filter: drop-shadow(15px -10px 3px #4444dd);
  color: #ab20fd;
}

@media screen and (max-width: 1700px) {
  .about-container {
    display: flex;
    flex-direction: column;
  }
  .about-info {
    display: flex;
  }
}

@media screen and (max-width: 900px) {
  .tech__section {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .about-container {
    font-size: 15px;
    line-height: 28px;
  }
}
