.projects__container {
  margin-top: 10rem;
  background: var(--main-color);
}

.projects__container h2 {
  font-family: var(--title-font);
  color: white;
  font-size: 40px;
  text-align: start;
  padding-top: 4rem;
  border-bottom: 1px solid var(--darker-main);
}

.projects {
  padding: 4rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* .project {
  width: 550px;
  margin: 2rem auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid var(--darker-main);
  position: relative;
  box-shadow: 10px 5px 5px var(--darker-main);
} */

.project {
  flex: 1 1 calc(50% - 2rem); /* 50% width minus margin */
  margin: 1rem; /* Adjust margin for spacing */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid var(--darker-main);
  position: relative;
  box-shadow: 10px 5px 5px var(--darker-main);
  max-width: 550px; /* Optional: To limit size on larger screens */
}

.project:hover .project_overlay {
  opacity: 0.8;
  -webkit-animation: flip-in-hor-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.project h3 {
  font-size: 20px;
  font-family: var(--text-font);
  margin-bottom: 10px;
  color: white;
}
.project p {
  font-size: 17px;
  color: rgb(145, 143, 143);
  font-weight: 300;
  font-family: var(--text-font);
}

.project .info {
  padding: 2rem;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.img-container {
  width: 100%;
  height: 300px;
  background-color: #fff;
}
.project img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  align-items: flex-end;
  padding: 1rem;
  justify-self: end;
  margin-top: auto;
}

.buttons button {
  border: none;
  border-bottom: 1px solid var(--darker-main);
  padding: 0.5rem;
  overflow: hidden;
}

.buttons * {
  font-family: var(--text-font);
  color: #fff;
}
.buttons button:hover * {
  color: #fff;
}

.tech__images {
  display: flex;
  height: 30px;
  padding: 0 1rem 1rem 1rem;
  bottom: 50px;
}

.tech__images img {
  height: 100%;
  object-fit: fill;
}

@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 690px) {
  .project_overlay p {
    display: none;
  }
  .buttons * {
    font-size: 15px;
    padding: 0.5rem;
  }
  .tech__images {
    display: flex;
    height: 35px;
    gap: 10px;
  }
  .projects {
    padding: 1rem;
  }
  .project .info {
    padding: 0.5rem;
  }
  .tech__images {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .project {
    flex: 1 1 calc(100% - 2rem); /* Full width for smaller screens */
  }
}

@media screen and (max-width: 690px) {
  .project {
    margin: 1rem auto;
  }
}
